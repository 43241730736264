<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="dark"
  >
    <CCard>
      <CCardBody>
        <CInput
          :label="$t('cloud.title.note')"
          :placeholder="$t('cloud.title.note')"
          horizontal
          v-model="counter_updated.note"
        />
      </CCardBody>
    </CCard>
    <template #header>
      <h6 class="modal-title">{{ $t('cloud.title.edit_something', {something: title}) }}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton @click="onSubmit" color="primary">{{$t('button.submit')}}</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: 'AppOccupancySettingForm',

  data() {
    return {
      show: false,

      title: 'Counter',
      counter_updated: {
        id: null,
        note: null
      },
      branch_name: null,
      sid: null,

      dismissCountDown: 0
    }
  },
  methods: {
    open(counter, title) {
      this.counter_updated = {
        sid: counter.sid,
        note: counter.note,
        branch_sid: counter.branch_sid
      };
      this.branch_name = counter.branch_name;
      this.title = title || this.title;
      this.show = true;
    },
    onCancel() {
      this.show = false;
      this.counter_updated = {
        sid: null,
        memo: null,
        branch_sid: null
      };
    },
    onSubmit() {
      this.$emit('payload', this.counter_updated);
      this.onCancel();
      this.show = false;
    }
  }
}
</script>
