<template>
  <div>
    <ConfirmModal ref="DeleteConfirmModal" @ok="onConfirmed" :content="$t('cloud.title.confirm_remove2')" />
    <OccupancyForm ref="FormModal" @payload="onPayload" />
    <CRow class="justify-content-center">
      <CCol xl="12" xxl="10">
        <Search class="my-4" @search="onSearch">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <CIcon name="supervised_user_circle" />
                <strong>{{$t('cloud.title.apps_occupancy')}}</strong>
                <span v-if="count > 0"> ({{count}}) </span>
              </slot>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                :items="list"
                :fields="FIELDS"
                :items-per-page="10"
              >
                <td slot="ops" slot-scope="{item, index}" class="text-center align-middle">
                  <el-dropdown trigger="click" @command="(command) => onOps(command, index, item)">
                    <span class="el-dropdown-link">
                      <i class="el-icon-more text-dark" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="edit" icon="el-icon-edit-outline">{{ $t('button.edit') }}</el-dropdown-item>
                      <el-dropdown-item command="delete" icon="el-icon-delete">{{ $t('button.delete') }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </td>
              </CDataTable>
            </CCardBody>
          </CCard>
        </Search>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import Search from '@/containers/Search.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import OccupancyForm from './Form.vue';

export default {
  name: 'AppOccupancySetting',
  components: {
    Search,
    ConfirmModal,
    OccupancyForm
  },
  data () {
    return {
      FIELDS: [
        {key: 'name', label: this.$t('cloud.title.name')},
        {key: 'sid', label: 'UUID'},
        {key: 'branch_name', label: this.$t('cloud.title.branch')},
        {key: 'device_name', label: this.$t('cloud.title.device')},
        {key: 'note', label: this.$t('cloud.title.note')},
        {key: 'ops', label: this.$t('cloud.title.operation'), _style: 'width:100px; text-align: center;' }
      ],

      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      searchText: null,
      count: 0,
      list: []
    }
  },
  mounted() {
    this.initFetch()
  },
  methods: {
    initFetch() {
      this.getList();
    },
    getList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/counter/occupancy/${query_string}`)
        .then(result => {
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
          this.list = this.$utility.copy(result.data.list);
          this.list = this.list.map(el => {
            if (el.note === null) el.note = "";
            return el;
          })
        })
        .catch(e => {
          console.error(e);
        })
    },
    onSearch(text) {
      this.searchText = text;
      this.getList();
    },
    onEdit(index) {
      const item = this.list[index];
      this.$refs.FormModal.open(item, this.$t('cloud.title.apps_occupancy'));
    },
    onPayload(payload) {
      axios.put(`/api/counter/occupancy/${payload.sid}/`, payload)
        .then(() => {
          this.getList();
          this.$notify.success({
            title: this.$t('cloud.title.success'),
            message: this.$t('cloud.title.request_done'),
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: this.$t('cloud.title.error'),
            message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
            offset: 30
          });
        })
    },

    onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    onDelete(index) {
      const item = this.list[index];
      this.$refs.DeleteConfirmModal.open(item.sid);
    },
    onConfirmed(payload) {
      axios.delete(`/api/counter/occupancy/${payload}/`)
        .then(() => {
          this.getList();
          this.$notify.success({
            title: this.$t('cloud.title.success'),
            message: this.$t('cloud.title.request_done'),
            offset: 30
          });
        })
        .catch(e => {
          console.error(e);
        });
    },
    onOps(cmd, index) {
      if (cmd === 'edit') {
        this.onEdit(index);
      } else if (cmd === 'delete') {
        this.onDelete(index);
      }
    },
  }
}
</script>
